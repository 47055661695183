<template>
  <table class="table table-bordered">
    <thead class="table-bordered">
      <tr>
        <th
          v-for="(th, key) in thList"
          :key="`th-${key}`"
          :style="`width: ${th.width};`"
          :class="{ 'cursor-pointer': th.sortBy }"
          class="text-small roboto-font font-weight-medium py-1-5 pl-1-5 pr-1"
          @click="th.sortBy ? chageSortEmit(th.sortBy) : ''"
        >
          <div class="d-flex justify-content-between">
            {{ $t(th.i18KeyLabel) }}
            <div v-if="th.sortBy" class="d-flex flex-column text-gray position-relative">
              <font-awesome-icon
                :icon="['fas', 'sort-up']"
                class="fa-w-10"
                :class="{ 'text-black': sortedUp(th.sortBy) }"
              ></font-awesome-icon>
              <font-awesome-icon
                :icon="['fas', 'sort-down']"
                class="fa-w-10"
                :class="{ 'text-black': sortedDown(th.sortBy) }"
              ></font-awesome-icon>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(item, key) in items"
        :key="`service-${key}`"
        :style="{ backgroundColor: item.status === 'Declined' ? 'rgba(255, 0, 0, 0.05)' : '' }"
      >
        <td class="py-0-7 px-1-5">
          <p class="text-black" style="margin-bottom: 5px;">{{ item.start_time | dateFormat(siteLang) }}</p>
          <p class="mb-0 text-black">
            {{ item.start_time | timeFormat(siteLang) }} - {{ item.end_time | timeFormat(siteLang) }}
          </p>
        </td>
        <td v-if="tableType !== 'clientOrders'" class="py-1 px-1-5">{{ item.client_name }}</td>
        <td class="py-0-7 px-1-5">
          <span v-if="item.parameter_values !== undefined">
            <span v-if="item.parameter_values[0] !== undefined">
              {{ $t(`common.whom_key.${item.parameter_values[0].value}`) }}
            </span>
          </span>
        </td>
        <td class="py-0-7 px-1-5">
          <span v-if="item.parameter_values !== undefined">
            <span v-if="item.parameter_values[1] !== undefined">
              {{ $t(`common.length_key.${item.parameter_values[1].value}`) }}
            </span>
          </span>
        </td>
        <!--   -->
        <td class="py-0-7 px-1-5">
          <div v-for="service in item.services" :key="service.service_id" class="d-flex flex-wrap">
            <span class="mr-1">{{ `${service.service_title} ` }}</span>
          </div>
        </td>
        <td class="py-0-7 px-1-5">{{ item.staff_fullName }}</td>

        <td v-if="tableType !== 'clientOrders'" class="py-0-7 px-1-5">{{ item.status }}</td>
        <td class="py-0-7 px-1-5">CHF {{ item.price }}</td>
      </tr>
    </tbody>
  </table>
</template>
<script>
import moment from 'moment'
import { mapGetters } from 'vuex'

export default {
  name: 'BookingsHistoryTable',
  filters: {
    dateFormat(val, locale) {
      return moment(val)
        .locale(locale)
        .format('MMM DD, YYYY')
    },
    timeFormat(val, locale) {
      return moment(val)
        .locale(locale)
        .format('hh:mm A')
    }
  },
  props: {
    items: {
      default: () => [],
      type: Array
    },
    tableType: {
      default: 'salonOrders',
      type: String
    }
  },
  data() {
    return {
      sortedBy: '-start_time'
    }
  },
  computed: {
    ...mapGetters({
      siteLang: 'user/getSiteLang'
    }),
    thList() {
      const tableHeader = [
        {
          i18KeyLabel: 'salon-bookings.table-label.date',
          sortBy: 'start_time',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.name',
          sortBy: 'client_name',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.for_whom',
          sortBy: 'parameter_values.0.value',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.hair_length',
          sortBy: 'parameter_values.2.value',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.service_name',
          sortBy: 'service_title',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.specialist',
          sortBy: 'staff_fullName',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.status',
          sortBy: 'status',
          width: 'auto'
        },
        {
          i18KeyLabel: 'salon-bookings.table-label.price',
          sortBy: 'price',
          width: 'auto'
        }
      ]
      return this.tableType === 'salonOrders'
        ? tableHeader
        : tableHeader.filter(col => col.sortBy !== 'client_name' && col.sortBy !== 'status')
    }
  },
  methods: {
    sortedUp(val) {
      return this.sortedBy === val
    },
    sortedDown(val) {
      return this.sortedBy === `-${val}`
    },
    chageSortEmit(val) {
      if (this.sortedBy === val) val = `-${val}`

      this.sortedBy = val
      this.$emit('change-sort', { sort: val })
    }
  }
}
</script>
