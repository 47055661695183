<template>
  <main class="navbar-offset-tablet salon-flow overflow-hidden pb-2">
    <PageNavigator
      :page-title="$t('salon-clients.client-profile-page-title')"
      :has-back="true"
      :button-back-link="lastCalandarPath ? lastCalandarPath : '/salon/clients'"
    >
      <template v-slot:action>
        <div>
          <button disabled class="btn btn-primary px-4 py-1">
            <span class="font-weight-medium poppins-font">{{ $t('common.submit-btn') }}</span>
          </button>
        </div>
      </template>
    </PageNavigator>
    <div class="px-1-5 pt-7-5">
      <div>
        <form class="row">
          <div class="col-12">
            <p class="font-weight-medium poppins-font mb-1-5 text-black">
              {{ $t('booking.forms.section.client_title') }}
            </p>
          </div>
          <div class="col-auto pr-2-5">
            <div class="d-flex">
              <div class="position-relative edit-profile-img px-0 mb-3-5">
                <img
                  v-if="clientProfileInfoFields.avatar_path"
                  :src="clientProfileInfoFields.avatar_path"
                  alt
                  class="w-100 h-100 image--cover rounded"
                />
                <div class="position-absolute">
                  <label class="custom-file-label"></label>
                </div>
              </div>
            </div>
          </div>
          <div class="col px-0">
            <div class="row">
              <div
                v-for="field in clientProfileInfoFields.textFields"
                :key="field.label"
                class="col-12 col-md-6 mb-1-5"
              >
                <label :for="field.label" class="poppins-font text-black font-weight-medium text-small">{{
                  field.label
                }}</label>
                <div class="input-group">
                  <input readonly :value="field.value" class="form-control w-100 px-1-5" />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <hr class="mb-2 mt-1" />

    <p class="px-1-5 font-weight-medium poppins-font text-black">
      {{ $t('salon-clients.order_scetion_label') }}
    </p>

    <div class="px-1-5">
      <BookingsHistoryTable table-type="clientOrders" :items="clientOrders.data" @change-sort="changeSort" />
    </div>
    <div class="px-1-5 pt-5-5">
      <CustomPagination
        :data="clientOrders"
        :show-disabled="true"
        @pagination-change-page="changePaginationPage"
      ></CustomPagination>
    </div>
  </main>
</template>

<script>
import BookingsHistoryTable from '@/components/salon/BookingsHistoryTable'
import CustomPagination from '@/components/CustomPagination'
import PageNavigator from '@/components/PageNavigator'
import ControlLastCalendarPath from '@/_mixins/ControlLastCalendarPath.vue'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ClientProfile',
  components: { BookingsHistoryTable, CustomPagination, PageNavigator },
  mixins: [ControlLastCalendarPath],
  data() {
    return {
      ordersListParams: {
        sort: '-start_time',
        page: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      clientProfileInfo: 'salonClients/getClientProfileInfo',
      clientOrders: 'salonClients/getClientOrders',
      accountInfo: 'user/getAccountInfo',
      lastCalandarPath: 'calendar/getLastCalandarPath'
    }),
    salonId() {
      return this.accountInfo.salon_id
    },
    clientId() {
      return this.$router.currentRoute.params.clientId
    },
    clientProfileInfoFields() {
      return {
        avatar_path: this.clientProfileInfo.avatar_path,
        textFields: [
          {
            label: `${this.$t('salon-days-off.form-label.label_first_name')}`,
            value: this.clientProfileInfo.firstName
          },
          {
            label: `${this.$t('salon-days-off.form-label.label_phone')}`,
            value: this.clientProfileInfo.phone || '-'
          },
          {
            label: `${this.$t('salon-days-off.form-label.label_lastname')}`,
            value: this.clientProfileInfo.lastName
          },
          {
            label: 'Email',
            value: this.clientProfileInfo.email
          }
        ]
      }
    }
  },
  mounted() {
    this.getProfileInfo(this.clientId)
  },
  methods: {
    ...mapActions({
      getClientsProfileInfo: 'salonClients/getClientsProfileInfo',
      setError: 'userMessages/setError'
    }),
    changeSort(newSortValue) {
      this.ordersListParams.sort = newSortValue.sort
      this.getClientsInfo()
    },
    getClientsInfo() {
      return this.getClientsProfileInfo({
        ...this.ordersListParams,
        salon_id: this.salonId,
        client_id: this.clientId
      }).catch(() => {
        this.setError({
          type: 'get_detailed_info_error',
          messageI18Key: 'common.alerts.actions.global_error'
        })
      })
    },
    changePaginationPage(page) {
      this.ordersListParams.page = page
      this.getClientsInfo()
    },
    getProfileInfo(clientId) {
      this.getClientsProfileInfo({ client_id: clientId, salon_id: this.salonId, sort: 'price' })
        .then(() => {
          this.isClientsDataLoading = false
        })
        .catch(() => {
          this.isClientsDataLoading = false
          this.setError({
            type: 'get_detailed_info_error',
            messageI18Key: 'common.alerts.actions.global_error'
          })
        })
    }
  }
}
</script>
