<script>
import { mapMutations } from 'vuex'
export default {
  methods: {
    ...mapMutations({
      setLastCalandarPath: 'calendar/SET_LAST_CALENDAR_PATH'
    })
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      const calendarRuoteNames = ['calendar-salon', 'calendar-staff']

      if (calendarRuoteNames.includes(from.name)) {
        vm.setLastCalandarPath(from.path)
      }
    })
  },
  beforeRouteLeave(to, from, next) {
    this.setLastCalandarPath(null)

    next()
  }
}
</script>

<style lang="scss" scoped></style>
