<template>
  <div class="d-flex justify-content-between w-100 align-items-center ">
    <p class="mb-0">
      <span class="text-black"> {{ data.current_page }} - {{ data.last_page }} </span>
      {{ $t('pagination.of') }} {{ data.total }} {{ $t('pagination.entries') }}
    </p>

    <RenderlessLaravelVuePagination :data="data" :limit="limit" @pagination-change-page="onPaginationChangePage">
      <ul
        v-if="computed.total > computed.perPage"
        slot-scope="{ data, limit, computed, prevButtonEvents, nextButtonEvents, pageButtonEvents }"
        class="pagination mb-0"
      >
        <li v-if="computed.prevPageUrl || showDisabled" class="page-item pagination-page-nav mr-3">
          <a
            href="#"
            aria-label="Previous"
            :class="{ disabled: !computed.prevPageUrl }"
            class="pagination-link"
            v-on="prevButtonEvents"
          >
            <slot name="prev-nav">
              <i class="prev-icon-tablet"></i>
            </slot>
          </a>
        </li>

        <li
          v-for="(page, key) in computed.pageRange"
          :key="key"
          class="page-item pagination-page-nav mr-1"
          :class="{ active: page == computed.currentPage }"
        >
          <a class="pagination-link" href="#" v-on="pageButtonEvents(page)">{{ page }}</a>
        </li>

        <li v-if="computed.nextPageUrl || showDisabled" class="page-item pagination-page-nav ml-2">
          <a
            href="#"
            aria-label="Next"
            class="pagination-link"
            :class="{ disabled: !computed.nextPageUrl }"
            v-on="nextButtonEvents"
          >
            <i class="next-icon-tablet"></i>
          </a>
        </li>
      </ul>
    </RenderlessLaravelVuePagination>
  </div>
</template>

<script>
import RenderlessLaravelVuePagination from 'laravel-vue-pagination/src/RenderlessLaravelVuePagination.vue'
export default {
  name: 'CustomPagination',
  components: {
    RenderlessLaravelVuePagination
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    limit: {
      type: Number,
      default: 0
    },
    showDisabled: Boolean
  },
  methods: {
    onPaginationChangePage(page) {
      this.$emit('pagination-change-page', page)
    }
  }
}
</script>

<style lang="scss">
.salon-flow {
  .pagination-link {
    padding: 8px 13px;
    &:first-child {
      padding: 8px 10px;
    }
    &:last-child {
      padding: 8px 10px;
    }
  }
}
</style>
