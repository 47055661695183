<template>
  <div
    class="d-flex justify-content-between align-items-center border-bottom px-1-5 position-fixed z-50 bg-white w-100"
    :class="{ 'py-2': !hasBack, 'py-0-8': hasBack }"
  >
    <slot name="left-panel">
      <div v-if="hasBack">
        <b-link :to="buttonBackLink" class="h6 mb-0 text-black">
          <i class="mr-1-5 back-icon"></i>{{ pageTitle }}
        </b-link>
      </div>
      <div v-else>
        <h6 class="mb-0 text-nowrap">
          {{ pageTitle }}
        </h6>
      </div>
    </slot>
    <slot name="action"></slot>
  </div>
</template>
<script>
export default {
  name: 'PageNavigator',
  props: {
    pageTitle: {
      type: String,
      required: true
    },
    hasBack: Boolean,
    buttonBackLink: {
      type: String
    }
  }
}
</script>
