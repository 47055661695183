var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex justify-content-between w-100 align-items-center "},[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"text-black"},[_vm._v(" "+_vm._s(_vm.data.current_page)+" - "+_vm._s(_vm.data.last_page)+" ")]),_vm._v("\n    "+_vm._s(_vm.$t('pagination.of'))+" "+_vm._s(_vm.data.total)+" "+_vm._s(_vm.$t('pagination.entries'))+"\n  ")]),_c('RenderlessLaravelVuePagination',{attrs:{"data":_vm.data,"limit":_vm.limit},on:{"pagination-change-page":_vm.onPaginationChangePage},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var data = ref.data;
var limit = ref.limit;
var computed = ref.computed;
var prevButtonEvents = ref.prevButtonEvents;
var nextButtonEvents = ref.nextButtonEvents;
var pageButtonEvents = ref.pageButtonEvents;
return (computed.total > computed.perPage)?_c('ul',{staticClass:"pagination mb-0"},[(computed.prevPageUrl || _vm.showDisabled)?_c('li',{staticClass:"page-item pagination-page-nav mr-3"},[_c('a',_vm._g({staticClass:"pagination-link",class:{ disabled: !computed.prevPageUrl },attrs:{"href":"#","aria-label":"Previous"}},prevButtonEvents),[_vm._t("prev-nav",[_c('i',{staticClass:"prev-icon-tablet"})])],2)]):_vm._e(),_vm._l((computed.pageRange),function(page,key){return _c('li',{key:key,staticClass:"page-item pagination-page-nav mr-1",class:{ active: page == computed.currentPage }},[_c('a',_vm._g({staticClass:"pagination-link",attrs:{"href":"#"}},pageButtonEvents(page)),[_vm._v(_vm._s(page))])])}),(computed.nextPageUrl || _vm.showDisabled)?_c('li',{staticClass:"page-item pagination-page-nav ml-2"},[_c('a',_vm._g({staticClass:"pagination-link",class:{ disabled: !computed.nextPageUrl },attrs:{"href":"#","aria-label":"Next"}},nextButtonEvents),[_c('i',{staticClass:"next-icon-tablet"})])]):_vm._e()],2):_vm._e()}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }